<!--申报列表-->
<template>
  <div>
    <el-row :gutter="20" style="margin-top:15px">
      <fieldset style="width: 97%">
        <legend>搜索信息</legend>
        <el-form :inline="true" :model="searchForm" class="demo-form-inline" ref="searchForm">
          <el-form-item style="margin-left: 10px">
            <el-date-picker
                v-model="searchForm.declarationDateArray"
                type="daterange"
                range-separator="至"
                start-placeholder="申报日期"
                end-placeholder="申报日期"
                value-format="yyyy-MM-dd"
                key="declareDate"
                clearable>
            </el-date-picker>
          </el-form-item>
          <el-form-item style="margin-left: 10px">
            <el-date-picker
                v-model="searchForm.spendMethodTimeArray"
                type="daterange"
                range-separator="至"
                start-placeholder="申报支付日期"
                end-placeholder="申报支付日期"
                value-format="yyyy-MM-dd"
                key="spendDate"
                clearable>
            </el-date-picker>
          </el-form-item>
          <el-form-item style="margin-left: 10px">
            <el-input v-model="searchForm.content" placeholder="申报描述" clearable></el-input>
          </el-form-item>
          <el-form-item style="margin-left: 10px">
            <el-select v-model="searchForm.spendMethodIds" multiple filterable placeholder="申报支付方式" key="spendMethodIds" clearable>
              <el-option
                  v-for="item in declareMethods"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 10px">
            <el-select v-model="searchForm.spendPeopleIds" multiple filterable placeholder="报销收款方式" key="spendProjectsIds" clearable>
              <el-option
                  v-for="item in spendPeopleArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 10px">
            <el-select v-model="searchForm.states" multiple filterable placeholder="申报状态" key="declareMethodIds" clearable>
              <el-option label="新建未提交" :value="0"></el-option>
              <el-option label="提交待审核" :value="1"></el-option>
              <el-option label="已审核待支付" :value="2"></el-option>
              <el-option label="已支付" :value="3"></el-option>
              <el-option label="已退回" :value="4"></el-option>
              <el-option label="已完成" :value="5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 10px">
            <el-select v-model="searchForm.declareIds" multiple filterable placeholder="申报人" key="spendProjectsIds" clearable>
              <el-option
                  v-for="item in declareArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <br>
          <el-form-item style="margin-left: 10px">
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
          <el-form-item style="margin-left: 10px">
            <el-button type="warning" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </fieldset>
      <el-table
          :data="SpendDeclareArray"
          border
          v-loading="loading"
          size="medium"
          element-loading-text="拼命加载中"
          max-height="630"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
          :default-sort = "{prop: 'createDate', order: 'descending'}"
          style="width: 99%">
        <el-table-column prop="declareName" label="申报人" align="center" width="100" fixed show-overflow-tooltip></el-table-column>
        <el-table-column prop="declarationDate" label="申报日期" align="center" width="120" sortable fixed></el-table-column>
        <el-table-column prop="spendMethodName" width="120" align="center" label="支付方式"></el-table-column>
        <el-table-column prop="spendAmount" width="125" align="center" label="申报总金额"></el-table-column>
        <el-table-column width="120" align="center" label="申报状态">
          <template slot-scope="scope">
            <span>
              {{scope.row.state === 0 ? "新建未提交" :scope.row.state === 1 ? "提交待审核" :scope.row.state === 2 ? "已审核待支付" :scope.row.state === 3 ? "已支付" :scope.row.state === 4 ? "已退回" : "申报已完成"}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="reviewerName" width="130" align="center" label="审核人"></el-table-column>
        <el-table-column prop="clearingPersonName" width="130" align="center" label="支付人"></el-table-column>
        <el-table-column prop="spendTotal" width="130" align="center" label="支出总个数" show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" width="270" align="center" label="申报描述" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="content" width="270" align="center" label="退回批注" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="140" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="success" size="mini" @click="declareEditOpen(scope.row)">详情</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <declareEdit :declare.sync="declare" :isOperation="isOperation" :declareEditState="declareEditState" v-if="declareEditState" @close="declareEditClose"></declareEdit>
  </div>
</template>

<script>
import declareEdit from "@/pages/spend/declare/declare-edit";
/*
* todo : 申报列表
* */
export default {
  name: "declare-list",
  created() {
    this.pageInit()
  },
  components: {
    declareEdit,
  },
  data() {
    return {
      declareMethods: [],
      spendPeopleArray: [],
      declare: {},
      declareEditState: false,
      isOperation: false,
      limit: 100,
      page: 1,
      total: 1,
      loading: true,
      SpendDeclareArray: [],
      declareArray: [],
      searchForm: {
        // 申报日期
        declarationDateArray: [],
        // 申报支付日期
        spendMethodTimeArray: [],
        // 申报描述
        content: '',
        // 申报支付方式
        spendMethodIds: [],
        // 报销收款方式
        spendPeopleIds: [],
        // 申报状态
        states: [],
        // 申报人
        declareIds: []
      }
    }
  },
  methods: {
    //查询申报列表
    // querySpendDeclareList() {
    //   this.$axios({
    //     method: "GET",
    //     url: "/spendDeclare/spendDeclareList",
    //     params: {
    //       page: this.page,
    //       limit: this.limit,
    //       declareId: JSON.parse(localStorage.getItem("emp")).id,
    //       tenantCrop: localStorage.getItem("tenantCrop"),
    //     }
    //   }).then(response => {
    //     this.loading = false
    //     this.SpendDeclareArray = response.data.data.list;
    //     this.total = response.data.data.total
    //   })
    // },
    handleSizeChange(val) {
      this.limit = val
      this.querySpendDeclareListByCondition();
    },
    handleCurrentChange(val) {
      this.page =val
      this.querySpendDeclareListByCondition()
    },
    declareEditOpen(val) {
      this.declare = val;
      this.declareEditState = true;
    },
    declareEditClose() {
      this.declareEditState = false
      this.querySpendDeclareListByCondition()
    },
    pageInit() {
      /* 搜索申报列表 */
      this.querySpendDeclareListByCondition();
      // 查询申报支付方式
      this.$selectUtils.queryAccountIds(0).then(response => {
        this.declareMethods = JSON.parse(response.data.data)
      })
      // 查询报销收款方式
      this.$selectUtils.querySpendPeopleIds().then(response => {
        this.spendPeopleArray = JSON.parse(response.data.data)
      })
      // 查询申报人
      this.$selectUtils.queryEmpIds().then(response => {
        this.declareArray = JSON.parse(response.data.data)
      })
    },
    // 根据条件查询申报
    querySpendDeclareListByCondition() {
      this.$axios({
        method: "POST",
        url: "/spendDeclare/spendDeclareListByCondition",
        data: {
          page: this.page,
          limit: this.limit,
          tenantCrop: localStorage.getItem("tenantCrop"),
          ...this.searchForm
        }
      }).then(response => {
        this.loading = false
        this.SpendDeclareArray = response.data.data.list;
        this.total = response.data.data.total
      })
    },
    reset() {
      this.searchForm.spendMethodTimeArray = []
      this.searchForm.content = ''
      this.searchForm.spendMethodIds = []
      this.searchForm.declarationDateArray = []
      this.searchForm.states = []
      this.searchForm.spendPeopleIds = []
      this.searchForm.declareIds = []
      this.loading = true
      this.querySpendDeclareListByCondition()
    },
    search() {
      this.loading = true
      this.querySpendDeclareListByCondition()
    }
  }
}
</script>

<style scoped>

</style>